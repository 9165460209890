import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Golden Palace is a popular Chinese takeaway restaurant located in
        Earlsfield, London. The restaurant offers a diverse menu of authentic
        Chinese dishes, including appetizers, soups, stir-fries, noodle dishes,
        and more. The menu features a variety of vegetarian and non-vegetarian
        options, catering to different dietary preferences.
      </Typography>

      <Typography variant="body1" paragraph>
        One of the standout features of Golden Palace is their dedication to
        using fresh and high-quality ingredients. The dishes are prepared using
        traditional cooking techniques to ensure that they are packed with
        flavour and are true to their Chinese origins. Golden Palace is a
        must-try for anyone looking for a delicious Chinese takeaway in
        Earlsfield, London.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
